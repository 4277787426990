<template>
  <div class="baberrage">

  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    barrageList: Array
  },

  setup () {
    return {

    }
  }
})
</script>
<style lang="less" scoped>
.baberrage{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
