<template>
  <div @touchmove.prevent="() => false">
    <div class="shop-cars" v-show="showShopCar">
      <div class="clear-car">
        <div class="clear-btn" @click="clearShopCar">
          <img src="@/assets/mobile/delete-car.png" alt="清空购物车" />
        清空购物车
        </div>
      </div>
      <a-spin tip="加载中..." :spinning="loading" style="width: 100%">
      <div class="car-goods-list" @touchmove.stop>
        <div class="goods" v-for="(v, k) in carDishList" :key="k">
          <div class="goods-image">
            <img :src="v.pic" :alt="v.name" />
          </div>
          <div class="goods-info">
            <h1 class="goods-title">{{ v.name }}</h1>
            <div class="goods-unit">单价：{{ v.sellingPrice }}</div>
          </div>
          <div class="goods-num">
            <a class="btn" @click.stop="onMinus(k)" v-if="v.count > 0">
              <img src="@/assets/mobile/minus.png" alt="" />
            </a>
            <div class="num" v-if="v.count > 0">{{ v.count }}</div>
            <a class="btn" @click.stop="onAdd(k)">
              <img src="@/assets/mobile/add.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      </a-spin>
    </div>
    <div
      class="cover"
      v-if="showShopCarCover"
      @click="close"
    ></div>
  </div>
</template>

<script>
import { defineComponent, toRaw, ref } from 'vue'
import { DishClass } from '@/apis/dish'

const dish = new DishClass()
export default defineComponent({
  props: {},

  setup () {
    const carDishList = ref([])
    const loading = ref(false)
    const showShopCar = ref(false)
    const showShopCarCover = ref(false)

    return {
      loading,
      carDishList,
      showShopCar,
      showShopCarCover
    }
  },

  data () {
    return {
      orders: []
    }
  },

  methods: {
    open () {
      if (this.showShopCarCover) {
        this.close()
      } else {
        this.orders = this.$store.state.data.SHOP_CART_DISHS
        if (this.orders.length > 0) {
          this.getDishs()
        } else {
          this.$message.info('购物车是空的')
        }
      }
    },
    clearShopCar () {
      this.orders = []
      this.carDishList = []

      this.$emit('clear')

      this.close()
    },
    getDishs () {
      const ids = []
      this.orders.map(e => {
        ids.push(e.dishId)
      })
      this.showShopCarCover = true
      this.showShopCar = true
      this.loading = true
      dish.getList(ids).then(resp => {
        this.originalCarGoodsList = resp
        this.setShopCartDishs()

        this.loading = false
      })
    },
    setShopCartDishs () {
      const dishs = []
      this.orders.map(x => {
        this.originalCarGoodsList.map(e => {
          if (x.dishId === e.id) {
            e.count = x.count
            dishs.push(toRaw(e))
          }
        })
      })
      this.carDishList = dishs
    },
    close () {
      this.showShopCar = false
      this.showShopCarCover = false
    },
    onMinus (index) {
      this.carDishList[index].count -= 1

      this.changeOrder(index, this.carDishList[index].count)
    },
    onAdd (index) {
      this.carDishList[index].count += 1

      this.changeOrder(index, this.carDishList[index].count)
    },

    changeOrder (index, e) {
      const dish = this.carDishList[index]
      dish.quantity = e
      this.$emit('change', dish)
    }
  }
})
</script>

<style lang="less" scoped>
.cover {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 995;
}
.shop-cars {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 996;
  padding-bottom: 1.10rem;
  width: 100%;
  overflow: hidden;
  background: #fff;
  border-radius: .15rem .15rem 0 0;
  box-shadow: 0 0 .40rem rgba(100, 255, 100, 0.2);
  .clear-car {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: .54rem;
    font-size: .18rem;
    color: #333;
    padding-right: .30rem;
    position: absolute;
    top: 0;
    width: 100%;
    height: .54rem;
    background: #fff;
    z-index: 1;
    img {
      display: block;
      height: .20rem;
      width: .20rem;
      margin-right: .10rem;
    }
    .clear-btn{
      display: flex;
      align-items: center;
    }
  }

  .car-goods-list {
    padding: .30rem;
    max-height: 6.00rem;
    min-height: 1.50rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.goods {
  display: flex;
  position: relative;
  margin-top: .40rem;
  .goods-image {
    width: 1.40rem;
    height: 1.40rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:.08rem;
    overflow: hidden;
    img {
      display: block;
      height: 100%;
    }
  }

  .goods-info {
    padding-left: .10rem;
    .goods-title {
      font-size: .30rem;
      color: #333333;
      line-height: .55rem;
      height: 1.10rem;
      padding: 0;
      margin: 0;
    }
    .goods-unit {
      font-size: .24rem;
      color: #999;
      line-height: .30rem;
    }
  }

  .goods-num {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    .btn {
      display: block;
      width: .50rem;
      height: .50rem;
      border-radius: 50%;
      box-shadow: 0 0 .10rem rgba(237, 125, 75, 0.5);
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .num {
      padding: 0 0.1rem;
      min-width: .50rem;
      text-align: center;
    }
  }
}
</style>
